import { Injectable, inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { UserProfile } from '@scriptac/common/core/models/user-profile';
import { CurrentUserService } from '@scriptac/common/core/services/current-user.service';
import { mainPageByUserType } from 'projects/web/src/app/route-paths';

/**
 * Guard that checks that user is authenticated.
 */
@Injectable({
	providedIn: 'root',
})
export class NonAuthGuard {

	private readonly currentUserService = inject(CurrentUserService);

	private readonly router = inject(Router);

	/** Determine if route could be achieved. */
	public canActivate(): Observable<boolean | UrlTree> {
		return this.check();
	}

	/**
	 * @inheritDoc
	 */
	public canLoad(): Observable<boolean | UrlTree> {
		return this.check();
	}

	/**
	 * Guard logic.
	 * Pass only not authenticated users.
	 * @param user User auth state.
	 */
	public guard(user: UserProfile | null): boolean | UrlTree {
		if (!user) {
			return true;
		}

		return this.router.createUrlTree(mainPageByUserType[user.userType]);
	}

	/**
	 * Check if user is authenticated in user service.
	 *
	 * If user is not authenticated, he will be redirected to login page by
	 * userService for further login.
	 * @param url Url to be used for redirect after login.
	 */
	protected check(): Observable<boolean | UrlTree> {
		return this.currentUserService.currentUser$.pipe(
			first(),
			map(user => this.guard(user)),
		);
	}
}
